@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: 'Gobold';
  src: url(../../style/fonts/goboldregularitalic.otf) format("opentype");
}

$font-title: 'Gobold';
$font-primary: 'Lato',Arial, sans-serif;
$primary: #FF0E10;

.tapaFondo{
	position: absolute;
	width: 100vw;
	height: 100vh;
	background-color: white;
}

#noEresUsuario{
	margin-bottom: 0px;
}
#botonAtras{
	color: black !important;
	padding: 5px 10px;
	margin-left: auto; 
	margin-right: 0;
}

.facebook {
	background-color: #3b5998 !important;
	color: white;
}

.twitter {
	background-color: #00acee !important;
	color: white;
}

.google {
	background-color: #E94235 !important;
}

.fa {
	margin-right: 10px;
}

button {
	color: white !important;
}

a {
	transition: .3s all ease;
	color: $primary;
	&:hover, &:focus {
		text-decoration: none !important;
		outline: none !important;
		box-shadow: none;
	}
	&:hover, &:active, &:focus {
		color: $primary;
		outline: none !important;
		text-decoration: none !important;
	}
}
h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	line-height: 1.5;
	font-weight: 400;
	font-family: $font-title;
	color: $black;
}

.bg-primary{
	background: $primary !important;
}

.ftco-section{
	padding: 7em 0;
}

.ftco-no-pt{
	padding-top: 0;
}
.ftco-no-pb{
	padding-bottom: 0;
}
//HEADING SECTION
.heading-section{
	font-size: 28px;
	color: $black;
}

//COVER BG
.wrap{
	width: 100%;
	overflow: hidden;
	background: $white;
	border-radius: 5px;
	-webkit-box-shadow: 0px 10px 34px -15px rgba(0,0,0,0.24);
	-moz-box-shadow: 0px 10px 34px -15px rgba(0,0,0,0.24);
	box-shadow: 0px 10px 34px -15px rgba(0,0,0,0.24);
}

.login-wrap{
	position: relative;
	h3{
		font-weight: 300;
	}
}



.form-group{
	position: relative;
	z-index: 0;
	margin-bottom: 20px !important;
	a{
		color: lighten($black,50%);
	}
}

//FORM CONTROL
.form-control {
	height: 48px;
	background: $white;
	color: $black;
	font-size: 16px;
	border-radius: 5px;
	box-shadow: none;
	border: 1px solid rgba(0,0,0,.1);
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: rgba(0,0,0,.2) !important;
	}
	&::-moz-placeholder { /* Firefox 19+ */
	  color: rgba(0,0,0,.2) !important;
	}
	&:-ms-input-placeholder { /* IE 10+ */
	  color: rgba(0,0,0,.2) !important;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: rgba(0,0,0,.2) !important;
	}
	&:focus, &:active {
		outline: none !important;
		box-shadow: none;
		border: 1px solid $primary;
	}
}
.form-control-social {
	height: 48px;
	color: $black;
	font-size: 16px;
	border-radius: 5px;
	box-shadow: none;
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: rgba(0,0,0,.2) !important;
	}
	&::-moz-placeholder { /* Firefox 19+ */
	  color: rgba(0,0,0,.2) !important;
	}
	&:-ms-input-placeholder { /* IE 10+ */
	  color: rgba(0,0,0,.2) !important;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: rgba(0,0,0,.2) !important;
	}
	&:focus, &:active {
		outline: none !important;
		box-shadow: none;
		border: 1px solid $primary;
	}
}

.field-icon {
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
	color: rgba(0,0,0,.3);
}

.form-control-placeholder {
  position: absolute;
  top: 2px;
  padding: 7px 0 0 15px;
  transition: all 400ms;
  opacity: .6;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  transform: translate3d(0, -120%, 0);
  padding: 7px 0 0 0;
  opacity: 1;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  color: $primary;
  font-weight: 700;
}


.social-media{
	position: relative;
	width: 100%;
	.social-icon{
		display: block;
		width: 40px;
		height: 40px;
		background: transparent;
		border: 1px solid rgba(0,0,0,.05);
		font-size: 16px;
		margin-right: 5px;
		border-radius: 50%;
		span{
			color: lighten($black,60%);
		}
		&:hover, &:focus{
			background: $primary;
			span{
				color: $white;
			}
		}
	}
}

//CHECKBOX STYLE
.checkbox-wrap {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "\f0c8";
  font-family: "FontAwesome";
  position: absolute;
  color:rgba(0,0,0,.1);
  font-size: 20px;
  margin-top: -4px;
  @include transition(.3s);
}

/* Show the checkmark when checked */
.checkbox-wrap{
	input:checked ~ .checkmark:after {
	  display: block;
	  content: "\f14a";
	  font-family: "FontAwesome";
	  color: rgba(0,0,0,.2);
	}
}

/* Style the checkmark/indicator */
.checkbox-wrap .checkmark:after {
}


.checkbox-primary{
  color: $primary;
  input:checked ~ .checkmark:after {
	  color: $primary;
	}
}


//BUTTON
.btn {
	cursor: pointer;
	box-shadow: none!important;
	font-size: 15px;
	padding: 10px 20px;
	&:hover, &:active, &:focus {
		outline: none;
	}
	&.btn-primary {
		background: $primary !important;
		border: 1px solid $primary !important;
		color: $white !important;
		&:hover {
			border: 1px solid $primary;
			background: transparent;
			color :$primary;
		}
		&.btn-outline-primary {
			border: 1px solid $primary;
			background: transparent;
			color :$primary;
			&:hover {
				border: 1px solid transparent;
				background: $primary;
				color :$white;
			}
		}
	}
}
