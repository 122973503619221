h1 {
  font-size: 3rem;
  padding: 0 1rem;
}

h2 {
  font-size: 2.5rem;
}

#iconTacograph {
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  background-color: red;
  color: white;
}
