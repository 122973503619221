@font-face {
    font-family: 'Gobold';
    src: url(fonts/goboldregularitalic.otf) format("opentype");
}

$red: #FF1011;
$black: #000000;
$grey: #666666;
$white: #FFFFFF;
$light-grey: #E7E7E7;
$font-title: "Gobold";

* {
    scroll-behavior: smooth;
    font-family: 'Lato';
    font-style: normal;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

.imagenProducto {
    height: 60%;
    width: auto;
}

h1, h2 {
    font-family: $font-title, sans-serif;
}

body {
    text-rendering: optimizeLegibility;
    word-wrap: break-word;
}

h2 {
    font-size: 30px;
    text-transform: uppercase;
}

a {
    text-decoration: none !important;
}

.header {
    width: 100vw;
}

.row-logo-contacto {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 30px;
}

.logo {
    height: 100%;
    max-width: 100%;
}

.contacto-linea {
    display: flex;
    align-content: center;
    height: 25px;

    .icon {
        padding-left: 2px;
        padding-right: 2px;
        width: 30px;
        text-align: center;
    }

    .contacto-texto {
        color: $grey;
    }
}

/* 
    NAVBAR 
*/

.navbar {
    background-color: $red;
}

#link-header {
    color: #fff !important;
    font-weight: 600 !important;
}

#botonMargen {
    position: absolute;
    background: black;
    min-width: 9rem;
    text-align: center;
}



@media (max-width: 991px) { 
    #botonMargen {
        position: static;
        text-align: left;
        left: 0;
        -webkit-border-top-right-radius: 20px;
        -webkit-border-bottom-right-radius: 20px;
        -moz-border-radius-topright: 20px;
        -moz-border-radius-bottomright: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        width: max-content;
    }
}

@media (min-width: 991px) {
    .derecho {
        right: 0;
        -webkit-border-top-left-radius: 20px;
        -webkit-border-bottom-left-radius: 20px;
        -moz-border-radius-topleft: 20px;
        -moz-border-radius-bottomleft: 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    
    .izquierdo {
        left: 0;
        -webkit-border-top-right-radius: 20px;
        -webkit-border-bottom-right-radius: 20px;
        -moz-border-radius-topright: 20px;
        -moz-border-radius-bottomright: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}


.fontTitulo {
    font-family: $font-title;
    color: #fff !important;
}




/*
NAVBARLINKS.JS
*/

#categoriasDropdown {
    height: 100%;
    border-radius: 0;
}

#buscador {
    border: 1px solid #000;
}

.container-productos {
    padding-left: 5%;
    padding-right: 5%;
}

.lineaSubrayado::after {
    content: ' ';
    border-bottom: 2px solid red;
    display: block;
    width: 40%;
    position: absolute;
}

.productos-busqueda {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
}


.productos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
}

.productos-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.producto {
    width: 275px;
    border: 1px solid $light-grey;
    margin: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.3s ease-in-out;
    height: 27rem;
    position: relative;

    .producto-imagen {
        height: 190px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img {
        max-width: 190px;
    }
}

/*.destacado {
    background: $red;
    color: #FFF;
    height: 70px;
    width: 70px;
    font-weight: 600;
    text-align: right;
    padding-top: 12px;
    padding-right: 12px;
    position: absolute;
    top: 1px;
    right: 1px;
    flex-direction: row;
    border-radius: 0 0 0 100%;
    border: 1px ridge #FFF;
    box-shadow: 0 0 0 3px $red;
  }
*/
.destacado {
    height: 115px;
    width: 115px;
    position: absolute;
    right: -8px;
    top: -8px;
    overflow: hidden;
}
.destacado .destacado-inside {
    background: $red;
    color: #FFF;
    transform: rotate(45deg);
    position: absolute;
    right: -35px;
    top: 7px;
    padding: 10px;
    min-width: 127px;
    text-align: center;
  }
  .destacado .destacado-inside:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #992C23;
    position: absolute;
    left: -4px;
    right: 0;
    bottom: 0;
    content: "";
    top: 40px;
    transform: rotate(-45deg);
  }
.destacado .destacado-inside:after {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 10px solid #992C23;
    border-bottom: 7px solid transparent;
    position: absolute;
    left: 93%;
    right: 0;
    top: 38px;
    bottom: 0;
    content: "";
    transform: rotate(-45deg);
  }


.producto:hover{
    box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.22);
    -webkit-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.22);
    -moz-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.22);
}

.categorias {
    display: flex;
    flex-direction: row;
}

.categoria {
    margin-right: 8px;
    background-color: $red;
    padding: 4px 6px;
    color: $white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.7rem;
    max-width: fit-content ;
}

.precio {
    margin: 5px 0px;
    font-weight: bold;
    font-family: $font-title;
    font-size: 2rem;
}

.precioPagina {
    font-size: 3rem;
}

.codigo {
    color: $grey;
    font-size: small;
}

.titulo {
    color: #444;
    font-size: 1rem;
    font-weight: 500;
}

.tituloPagina {
    font-size: 3rem;
}


.search {
    width: 100%;
    display: flex;
}

.searchTerm {
    width: 100%;
    border: 3px solid $grey;
    border-right: none;
    padding: 5px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: $grey;
}

.searchTerm:focus {
    color: $grey;
}

.searchButton {
    width: 40px;
    height: 36px;
    border: 1px solid $grey;
    background: $grey;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
    width: 30%;
    top: 50%;
    left: 50%;
}

/* MARCAS */
.marcas {
    margin-top: 60px;
    margin-bottom: 30px;
    h2 {
        text-align: center;
    }
}

.marcas-imagenes {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    gap: 15px;
}

.marcas-titulo {
    text-align: center;
}

.marca-imagen {
    width: 120px;
    height: auto;
    object-fit: contain;
    gap: 3rem;
}


/* CONTACTO */

.contacto {
    padding-top: 45px;
    padding-bottom: 55px;
    padding-left: 5%;
    padding-right: 5%;
    background-color: $light-grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    form {
        display: flex;
        flex-direction: column;

        input,
        textarea {
            width: 80%;
            border-radius: 8px;
            border: 1px solid $grey;
            height: 30px;
        }

        label {
            width: 20%;
            text-align: right;
            margin-right: 15px;
            text-transform: uppercase;
            color: $grey;
        }

        .boton-enviar {
            margin-right: 8px;
            background-color: $red;
            padding: 4px 6px;
            color: $white;
            font-weight: 500;
            font-size: 85%;
            text-transform: uppercase;
            border: 0px;
            border-radius: 0px;
            width: 135px;
            margin-left: auto;
            margin-right: 0;
        }

        textarea {
            height: 150px;
        }
    }
}

.contacto-titulo {
    color: $red;
    text-align: left;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 50px;
}

.contacto-row {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

/* Whatsapp */
.float{
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    left:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    z-index:100;
}

.my-float{
	margin-top:16px;
}

/* Footer */
footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 40px;
    margin-bottom: 40px;

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    a {
        color: $grey;
        text-decoration: none;
    }
    #contacto-footer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.copyright {
    height:39px;
    background-color: $light-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        text-align: center;
        color: $black;
    }
}

