.mapa {
    
    iframe {
        width: 100%;
        
    }
}

@media (min-width: 768px){
    .mapa {
    }
}