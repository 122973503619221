@font-face {
  font-family: 'Gobold';
  src: url(fonts/goboldregularitalic.otf) format("opentype");
}

* {
  scroll-behavior: smooth;
  font-family: 'Lato', Arial, Helvetica, Verdana, Trebuchet, Tahoma, Futura, sans-serif;
  font-style: normal;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

.imagenProducto {
  max-height: 600px;
  width: auto;
}

h1, h2 {
  font-family: "Gobold", sans-serif;
}

body {
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}

h2 {
  font-size: 30px;
  text-transform: uppercase;
}

a {
  text-decoration: none !important;
}

.header {
  width: 100vw;
}

.row-logo-contacto {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 30px;
}

.logo {
  height: 100%;
  max-width: 100%;
}

.contacto-linea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  height: fit-content;
  margin-top: 4px;
}

.contacto-linea .icon {
  padding-left: 2px;
  padding-right: 2px;
  width: 30px;
  text-align: center;
}

.icon {
  color: #db0e0e;
  border: 1px #000;
}

.contacto-linea .contacto-texto {
  color: #666666;
}

/* 
    NAVBAR 
*/
.navbar {
  background-color: #FF1011;
}

#link-header {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 1.1rem;
}

#botonMargen {
  position: absolute;
  min-width: 9rem;
  text-align: center;
  border-radius: 15px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.49) !important;
  border-radius: 18px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)!important;
  backdrop-filter: blur(8.2px) !important;
  -webkit-backdrop-filter: blur(8.2px) !important;
  border: 1px solid rgba(255, 255, 255, 0.3)!important;
  transition: all ease .3s !important;
  height: max-content;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

#botonMargen:hover{
  background: #db0e0e!important;
  transform: scale(1.2)!important;
  border: 1px solid #db0e0e!important;
  
}

@media (max-width: 991px) {
  #botonMargen {
    position: static;
    text-align: left;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background: #FF1011 !important;
    border: 0px !important;
    box-shadow: none !important;
  }
  .fa-inverse{
    padding-right: 1rem;
  }
  button svg{
    padding-right: 1rem;
  }
}

@media (min-width: 991px) {
  .derecho {
    right: 0;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-bottomleft: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .izquierdo {
    left: 0;
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    -moz-border-radius-topright: 20px;
    -moz-border-radius-bottomright: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.fontTitulo {
  font-family: "Gobold";
  color: #fff !important;
}

/*
NAVBARLINKS.JS
*/
#categoriasDropdown {
  height: 100%;
  border-radius: 0;
}

#buscador {
  border: 1px solid #000;
}

.container-productos {
  padding-left: 5%;
  padding-right: 5%;
}

.lineaSubrayado::after {
  content: ' ';
  border-bottom: 2px solid red;
  display: block;
  width: 40%;
  position: absolute;
}

.productos-busqueda {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.productos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.productos-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.producto {
  width: 275px;
  border: 1px solid #E7E7E7;
  margin: 10px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  height: 27rem;
  position: relative;
}

.producto .producto-imagen {
  height: 190px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.producto img {
  max-width: 190px;
}

.destacado {
  height: 115px;
  width: 115px;
  position: absolute;
  right: -8px;
  top: -8px;
  overflow: hidden;
}

.destacado .destacado-inside {
  background: #FF1011;
  color: #FFF;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  right: -35px;
  top: 7px;
  padding: 10px;
  min-width: 127px;
  text-align: center;
}

.destacado .destacado-inside:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #992C23;
  position: absolute;
  left: -4px;
  right: 0;
  bottom: 0;
  content: "";
  top: 40px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.destacado .destacado-inside:after {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 10px solid #992C23;
  border-bottom: 7px solid transparent;
  position: absolute;
  left: 93%;
  right: 0;
  top: 38px;
  bottom: 0;
  content: "";
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.producto:hover {
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.22);
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.22);
}

.categorias {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.categoria {
  margin-right: 8px;
  background-color: #FF1011;
  padding: 4px 6px;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.7rem;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}



.precio {
  margin: 5px 0px;
  font-weight: bold;
  font-family: "Gobold";
  font-size: 2rem;
}

.precioPagina {
  font-size: 3rem;
}

.codigo {
  color: #666666;
  font-size: small;
}

.titulo {
  color: #444;
  font-size: 1rem;
  font-weight: 500;
}

.tituloPagina {
  font-size: 3rem;
}

.search {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.searchTerm {
  width: 100%;
  border: 3px solid #666666;
  border-right: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #666666;
}

.searchTerm:focus {
  color: #666666;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #666666;
  background: #666666;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 30%;
  top: 50%;
  left: 50%;
}

/* MARCAS */
.marcas {
  margin-top: 60px;
  margin-bottom: 30px;
}

.marcas h2 {
  text-align: center;
}

.marcas-imagenes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 15px;
}

.marcas-titulo {
  text-align: center;
}

.marca-imagen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
}

.marca-imagen {
  width: 120px;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  gap: 3rem;
}

/* CONTACTO */
.contacto {
  padding-top: 45px;
  padding-bottom: 55px;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #E7E7E7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.contacto form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.contacto form input,
.contacto form textarea {
  width: 80%;
  border-radius: 8px;
  border: 1px solid #666666;
  height: 30px;
}

.contacto form label {
  width: 20%;
  text-align: right;
  margin-right: 15px;
  text-transform: uppercase;
  color: #666666;
}

.contacto form .boton-enviar {
  margin-right: 8px;
  background-color: #FF1011;
  padding: 4px 6px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 85%;
  text-transform: uppercase;
  border: 0px;
  border-radius: 0px;
  width: 135px;
  margin-left: auto;
  margin-right: 0;
}

.contacto form textarea {
  height: 150px;
}

.contacto-titulo {
  color: #FF1011;
  text-align: left;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 50px;
}

.contacto-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 10px;
}

/* Whatsapp */
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

/* Footer */
.imagen-footer {
  max-width: 100%;
}
footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  margin-top: 40px;
  margin-bottom: 40px;
}

footer ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

footer a {
  color: #666666;
  text-decoration: none;
}

footer #contacto-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.contacto-numeros{
  display: flex;
  flex-direction: column;
}

.copyright {
  height: 39px;
  background-color: #E7E7E7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.copyright p {
  text-align: center;
  color: #000000;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 0px 0px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
